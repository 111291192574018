var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-UserListsView container mt-5 p-5"
  }, [_c('div', {
    staticClass: "row gy-4"
  }, _vm._l(_vm.userLists, function (list) {
    return _c('div', {
      key: list._id,
      staticClass: "col-12 align-items-center d-flex border-bottom"
    }, [_c('b', {
      staticClass: "fs-1 me-5"
    }, [_c('a', {
      staticClass: "text-black text-decoration-none",
      attrs: {
        "href": ((_vm.frontUrl) + "/#/" + (list.shortCode))
      }
    }, [_vm._v(" " + _vm._s(list.name) + " ")])]), _c('a', {
      staticClass: "btn btn-success",
      attrs: {
        "href": ((_vm.frontUrl) + "/#/" + (list.shortCode))
      }
    }, [_vm._v(" " + _vm._s(list.shortCode) + " ")])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }