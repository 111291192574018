var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-EnterView w-100 my-auto"
  }, [_c('div', {
    staticClass: "container text-center"
  }, [_c('form', {
    staticClass: "w-40",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.handleForm.apply(null, arguments);
      }
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "row gx-2 justify-content-center"
  }, [_c('div', {
    staticClass: "col col-xl-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.playerName,
      expression: "playerName"
    }],
    staticClass: "form-control form-control-lg w-100 fs-1 mb-2 text-center",
    attrs: {
      "type": "text",
      "id": "enter-code-input"
    },
    domProps: {
      "value": _vm.playerName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.playerName = $event.target.value;
      }
    }
  })]), _vm._m(1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_vm._l(_vm.playerNameErrors, function (error, key) {
    return _c('h4', {
      key: key,
      staticClass: "text-danger"
    }, [_vm._v(" " + _vm._s(error.message) + " ")]);
  }), _c('label', {
    attrs: {
      "for": "enter-code-input"
    }
  }, [_vm._v("Wpisz swoje imię/pseudonim/nr dziennika itp")])], 2)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('h1', [_vm._v("Twoje imię")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col col-auto"
  }, [_c('button', {
    staticClass: "btn btn-primary text-light btn-lg"
  }, [_c('i', {
    staticClass: "bi bi-check fs-1"
  })])]);
}]

export { render, staticRenderFns }